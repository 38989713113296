<template>
  <div>
    <div>
      <Institution :callbackSelect="selectSchool"></Institution>
    </div>
    <div>
      <div>
        <v-card outlined>
          <v-card-title> Send Finisher List</v-card-title>
          <!-- <v-card-actions>
            <v-btn
              color="primary"
              text
              :disabled="lang == 'ms'"
              @click="changeLanguage('ms')"
            >
              Bahasa Melayu
            </v-btn>
            <v-btn
              color="primary"
              text
              :disabled="lang == 'zh'"
              @click="changeLanguage('zh')"
            >
              中文
            </v-btn>
            <v-btn
              color="primary"
              text
              :disabled="lang == 'en'"
              @click="changeLanguage('en')"
            >
              English
            </v-btn>
            <v-btn
              color="primary"
              text
              :disabled="lang == 'zh_ms'"
              @click="changeLanguage('zh_ms')"
            >
              双语（中/马来）
            </v-btn>
          </v-card-actions> -->
          <v-card-text>
            <v-textarea
              outlined
              :label="$t('model.prop.message')"
              :value="translatedMessage"
              v-model="translatedMessage"
            ></v-textarea>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text :disabled="copied" @click="copy">
              <span v-if="copied">{{ $t("action.copied") }}</span>
              <span v-else>{{ $t("action.copy") }}</span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Institution from "./Institution.vue";
export default {
  components: {
    Institution,
  },
  computed: mapState({
    //
  }),
  props: [],
  data: () => ({
    message: {
      en: "",
      zh: "",
      ms: "",
      zh_ms: "",
    },
    lang: "",
    translatedMessage: "",
    copied: false,
    selectedSchool: null,
    schoolYears: null,
    //BOC:[api]
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
    classrooms: [],
  }),
  created() {
    this.api.url = `${this.$api.servers.event}/api/v1/en/moderator/finisher/getByInstitution`;

    this.api.callbackReset = () => {
      this.api.isLoading = true;
      this.api.isError = false;
    };
    this.api.callbackError = (e) => {
      this.api.isLoading = false;
      this.api.isError = true;
      this.api.error = e;
    };
    this.api.callbackSuccess = (resp) => {
      this.api.isLoading = false;
      console.log(resp);
      this.message.en = `${this.selectedSchool.institutionName} - ${this.selectedSchool.town} \n\nFinisher\n`;
      const grouped = this.$_.groupBy(resp, "classroomName");

      this.classrooms = this.$_.map(grouped, (students, classroomName) => {
        const studentNames = this.$_.map(students, (student) =>
          this.$_.pick(student, "name")
        );
        const count = students.length;
        return { classroomName, count, studentNames };
      });
      for (var i = 0; i < this.classrooms.length; i++) {
        this.message.en =
          this.message.en +
          this.classrooms[i].classroomName +
          ": " +
          this.classrooms[i].count +
          "\n";
      }

      this.message.en = this.message.en + "Total: " + resp.length + "\n\n";

      for (var j = 0; j < this.classrooms.length; j++) {
        this.message.en = this.message.en + this.classrooms[j].classroomName + "\n";
        for (var k = 0; k < this.classrooms[j].studentNames.length; k++) {
          this.message.en =
            this.message.en + this.classrooms[j].studentNames[k].name + "\n";
        }
        this.message.en =
            this.message.en + "\n"
      }
      this.translatedMessage = this.message[this.lang];
    };
  },
  mounted() {
    //this.lang = this.$_getLocale();
    this.lang = "en";
  },
  methods: {
    selectSchool(school) {
      this.selectedSchool = school;
      this.api.params = {
        institutionName: this.selectedSchool.institutionName,
        town: this.selectedSchool.town,
      };
      this.$api.fetch(this.api);
    },
    changeLanguage(lang) {
      this.lang = lang;
      this.translatedMessage = this.message[this.lang];
      this.copied = false;
    },
    copy() {
      navigator.clipboard.writeText(this.translatedMessage);
      this.copied = true;
      var that = this;
      setTimeout(() => {
        if (that && that.copied) that.copied = false;
      }, 800);
    },
  },
};
</script>