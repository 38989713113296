<template>
  <v-container>
    <!-- BOC:[breadcrumbs] -->
    <ABreadcrumbV1 :items="breadcrumbs"></ABreadcrumbV1>
    <!-- EOC -->
    <!-- BOC:[header] -->
    <v-row class="mb-3">
      <v-col
        ><h1>{{ $t("model.name.finishers") }}</h1></v-col
      >
    </v-row>
    <!-- EOC -->
    <List></List>
  </v-container>
</template>

<script>
//BOC:[model]
import model from "@/models/items/finisher";
//EOC
import { mapState } from "vuex";
import List from "../../components/Moderator/Finisher/List.vue";
export default {
  components: {
    //BOC:[table]
    List,
    //EOC
  },
  computed: mapState({
    school: (state) => state.school.data,
  }),
  props: [
    //
  ],
  data: () => ({
    //BOC:[breadcrumbs]
    breadcrumbs: [],
    //EOC
    //BOC:[model]
    model: model,
    //EOC
    //BOC:[role]
    role: "Moderator",
    //EOC
  }),
  created() {
    //BOC:[breadcrumbs]
    this.breadcrumbs.push({
      text: this.$t("model.name.dashboard"),
      to: { name: "Page" + this.role + "Dashboard" },
      exact: true,
    });
    //
    this.breadcrumbs.push({
      text: this.$t("model.name." + this.model.name.plural.toLowerCase()),
      to: this.$_getRouteBrowse(this.role, this.model.key),
      exact: true,
    });

    this.breadcrumbs.push({
      text: 'List',
      to: {name:"PageModeratorFinisherList"},
      exact: true,
    });
    //EOC
  },
  mounted() {
    //
  },
  methods: {
    //
  },
};
</script>